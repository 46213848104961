import axios from 'axios';
var OrderService = /** @class */ (function () {
    function OrderService() {
    }
    OrderService.prototype.addToCart = function (reference, quantity, origin) {
        return axios.post('/api/ivy-orders/v2/order/', {
            reference: reference,
            quantity: quantity,
            origin: origin
        }, {
            withCredentials: true,
        });
    };
    OrderService.prototype.get = function () {
        return axios.get('/api/ivy-orders/v2/order/', {
            withCredentials: true
        });
    };
    OrderService.prototype.update = function (order) {
        return axios.put('/api/ivy-orders/v2/order/', order, {
            withCredentials: true
        });
    };
    OrderService.prototype.deleteDiscount = function () {
        return axios.delete('/api/ivy-orders/v2/order/discount', {
            withCredentials: true
        });
    };
    OrderService.prototype.deleteItem = function (reference) {
        return axios.delete('/api/ivy-orders/v2/order/items/' + reference, {
            withCredentials: true
        });
    };
    OrderService.prototype.updateItem = function (reference, itemUpdate) {
        return axios.put('/api/ivy-orders/v2/order/items/' + reference, itemUpdate, {
            withCredentials: true
        });
    };
    OrderService.prototype.getWithRecommendations = function () {
        return axios.post('/api/ivy-recommendations/v1/recommendations/order/', null, {
            withCredentials: true
        });
    };
    OrderService.prototype.downloadQuotation = function () {
        axios.get('/api/ivy-documents/v1/generate/quotation', {
            withCredentials: true,
            responseType: 'blob'
        }).then(function (response) {
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'quotation.docx');
            link.click();
        }).catch(function (error) {
            console.log(error);
        });
    };
    return OrderService;
}());
export default new OrderService();
